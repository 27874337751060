import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const social = {
  _tag: `Social`,
  facebookUrl: `https://www.facebook.com/MuniBondLink`,
  linkedInUrl: `https://www.linkedin.com/company/bondlink/`,
  twitterUrl: `https://twitter.com/bondlink`
} as const;

export const socialC = t.type({
  _tag: t.literal(`Social`),
  facebookUrl: t.literal(`https://www.facebook.com/MuniBondLink`),
  linkedInUrl: t.literal(`https://www.linkedin.com/company/bondlink/`),
  twitterUrl: t.literal(`https://twitter.com/bondlink`)
});
export type SocialC = typeof socialC;
export type Social = t.TypeOf<SocialC>;


export const bLS3ConfigC = t.type({
  cdn: t.string,
  issuerBucket: t.string,
  issuerBulkUploadBucket: t.string,
  issuerPrivateBucket: t.string,
  issuerBidSubmissionsBucket: t.string
});
export type BLS3ConfigC = typeof bLS3ConfigC;
export type BLS3Config = t.TypeOf<BLS3ConfigC>;


export const bLColorsConfigC = t.type({
  primary: t.string,
  secondary: t.string
});
export type BLColorsConfigC = typeof bLColorsConfigC;
export type BLColorsConfig = t.TypeOf<BLColorsConfigC>;


export const bLSecReqsConfigC = t.type({
  sessExpDur: t.number
});
export type BLSecReqsConfigC = typeof bLSecReqsConfigC;
export type BLSecReqsConfig = t.TypeOf<BLSecReqsConfigC>;


export const bLPlasmicCMSConfigC = t.type({
  id: t.string,
  publicToken: t.string
});
export type BLPlasmicCMSConfigC = typeof bLPlasmicCMSConfigC;
export type BLPlasmicCMSConfig = t.TypeOf<BLPlasmicCMSConfigC>;


export const DEV = {
  _tag: `DEV`
} as const;

export const DEVTaggedC = t.type({
  _tag: t.literal(`DEV`)
});
export type DEVTaggedC = typeof DEVTaggedC;
export type DEVTagged = t.TypeOf<DEVTaggedC>;
export type DEV = DEVTagged & typeof DEV;
export const DEVC = pipe(DEVTaggedC, c => new t.Type<DEV, DEVTagged>(
  `DEV`,
  (u: unknown): u is DEV => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DEV> => pipe(c.decode(u), E.map(x => ({ ...x, ...DEV }))),
  (x: DEV): DEVTagged => ({ ...x, _tag: `DEV`}),
));
export type DEVC = typeof DEVC;


export const STAGING = {
  _tag: `STAGING`
} as const;

export const STAGINGTaggedC = t.type({
  _tag: t.literal(`STAGING`)
});
export type STAGINGTaggedC = typeof STAGINGTaggedC;
export type STAGINGTagged = t.TypeOf<STAGINGTaggedC>;
export type STAGING = STAGINGTagged & typeof STAGING;
export const STAGINGC = pipe(STAGINGTaggedC, c => new t.Type<STAGING, STAGINGTagged>(
  `STAGING`,
  (u: unknown): u is STAGING => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, STAGING> => pipe(c.decode(u), E.map(x => ({ ...x, ...STAGING }))),
  (x: STAGING): STAGINGTagged => ({ ...x, _tag: `STAGING`}),
));
export type STAGINGC = typeof STAGINGC;


export const PRODUCTION = {
  _tag: `PRODUCTION`
} as const;

export const PRODUCTIONTaggedC = t.type({
  _tag: t.literal(`PRODUCTION`)
});
export type PRODUCTIONTaggedC = typeof PRODUCTIONTaggedC;
export type PRODUCTIONTagged = t.TypeOf<PRODUCTIONTaggedC>;
export type PRODUCTION = PRODUCTIONTagged & typeof PRODUCTION;
export const PRODUCTIONC = pipe(PRODUCTIONTaggedC, c => new t.Type<PRODUCTION, PRODUCTIONTagged>(
  `PRODUCTION`,
  (u: unknown): u is PRODUCTION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PRODUCTION> => pipe(c.decode(u), E.map(x => ({ ...x, ...PRODUCTION }))),
  (x: PRODUCTION): PRODUCTIONTagged => ({ ...x, _tag: `PRODUCTION`}),
));
export type PRODUCTIONC = typeof PRODUCTIONC;


export const allBLEnvC = [DEVC, STAGINGC, PRODUCTIONC] as const;
export const allBLEnvNames = [`DEV`, `STAGING`, `PRODUCTION`] as const;
export type BLEnvName = (typeof allBLEnvNames)[number];

export const BLEnvCU = t.union([DEVC, STAGINGC, PRODUCTIONC]);
export type BLEnvCU = typeof BLEnvCU;
export type BLEnvU = t.TypeOf<BLEnvCU>;

export const bLEnvOrd: Ord.Ord<BLEnvU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBLEnv = [DEV, STAGING, PRODUCTION] as const;
export type BLEnvMap<A> = { [K in BLEnvName]: A };


export const bLConfigC = t.type({
  csrf: t.string,
  baseUrl: t.string,
  currentBaseUrl: t.string,
  commit: t.string,
  environment: BLEnvCU,
  s3: bLS3ConfigC,
  colors: bLColorsConfigC,
  recaptchaKey: t.string,
  recaptchaLoaded: t.boolean,
  emmaCDDetails: t.string,
  customerSuccessEmail: t.string,
  bondlinkPrimaryEmail: t.string,
  bondlinkPrimaryTermsUrl: t.string,
  secReqs: optionFromNullable(bLSecReqsConfigC),
  plasmicCMS: bLPlasmicCMSConfigC
});
export type BLConfigC = typeof bLConfigC;
export type BLConfig = t.TypeOf<BLConfigC>;


